<template>
  <transition name="fade">
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div class="bg-white p-8 rounded-lg shadow-lg w-xl max-w-4xl mx-4 transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
        <h2 class="text-2xl font-bold text-admin-200 mb-4 text-center">
          Campaign Details
        </h2>
        <div v-if="metadata">
          <div class="mb-4">
            <label for="trackingId" class="block font-semibold">Tracking ID</label>
            <input
                v-model="metadata.tracking_id"
                type="text"
                id="trackingId"
                class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                disabled
            />
          </div>
          <div class="mb-4">
            <label for="redirectUrl" class="block font-semibold">Redirect URL</label>
            <input
                v-model="metadata.redirect_url"
                type="text"
                id="redirectUrl"
                class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                disabled
            />
          </div>
        </div>
        <div v-if="selectedCampaign && selectedCampaign.length" class="mt-6">
          <h3 class="text-xl text-center font-bold text-admin-200 mb-2">
            Tracking Data
          </h3>
          <div class="overflow-x-auto">
            <table
                class="min-w-full bg-white border border-gray-300 rounded-md">
              <thead class="bg-admin-200">
              <tr>
                <th class="text-left text-white px-4 py-2 border-b">Referrer URL</th>
                <th class="text-left text-white px-4 py-2 border-b">Click ID (bid)</th>
                <th class="text-left text-white px-4 py-2 border-b">Geo Location</th>
                <th class="text-left text-white px-4 py-2 border-b">Click Timestamp</th>
                <th class="text-left text-white px-4 py-2 border-b">Device Type</th>
                <th class="text-left text-white px-4 py-2 border-b">OS</th>
                <th class="text-left text-white px-4 py-2 border-b">Browser</th>
                <th class="text-left text-white px-4 py-2 border-b">IP Address</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(row, index) in paginatedData"
                  :key="index"
                  class="hover:bg-gray-100 transition-colors"
              >
                <td class="px-4 py-2 border-b">{{ row.referrer_url }}</td>
                <td class="px-4 py-2 border-b">{{ row.bid }}</td>
                <td class="px-4 py-2 border-b">{{ row.geo_location }}</td>
                <td class="px-4 py-2 border-b">{{ row.click_timestamp }}</td>
                <td class="px-4 py-2 border-b">{{ row.device_type }}</td>
                <td class="px-4 py-2 border-b">{{ row.os }}</td>
                <td class="px-4 py-2 border-b">{{ row.browser }}</td>
                <td class="px-4 py-2 border-b">{{ row.ip_address }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <PaginationComponent
              :currentPage="currentPage"
              :totalPages="totalPages"
              @update:currentPage="updateCurrentPage"
          />
        </div>

        <!-- Close Button -->
        <div class="mt-6 flex justify-end">
          <button
              @click="closeModal"
              class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";

export default {
  name: "CampaignDetailsModal",
  components: { PaginationComponent },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    metadata: {
      type: Object,
      default: () => ({}),
    },
    selectedCampaign: {
      type: Array,
      default: () => [],
    },
    paginatedData: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  emits: ["close", "update:currentPage"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
    updateCurrentPage(page) {
      this.$emit("update:currentPage", page);
    },
  },
};
</script>
