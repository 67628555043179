<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">{{ $translate('sites.add_site.title') }}</h2>
        <form @submit.prevent="handleAddSite">
          <!-- Site ID -->
          <div class="mb-4">
            <input
                type="text"
                v-model="id"
                :placeholder="$translate('placeholders.site_id')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.id" class="text-red-500 text-sm">{{ errors.id }}</p>
          </div>

          <!-- Site Name -->
          <div class="mb-4">
            <input
                type="text"
                v-model="name"
                :placeholder="$translate('placeholders.site_name')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.name" class="text-red-500 text-sm">{{ errors.name }}</p>
          </div>

          <!-- Site URL -->
          <div class="mb-4">
            <input
                type="text"
                v-model="url"
                :placeholder="$translate('placeholders.site_url')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.url" class="text-red-500 text-sm">{{ errors.url }}</p>
          </div>
          <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
<!--            select client-->
            <select class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500">
              <option value="">{{ $translate('select_client') }}</option>
              <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
            </select>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-center mt-6">
            <button
                :disabled="isSubmitting || !isFormValid"
                type="submit"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
            >
              {{ $translate('add_site') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: {DashboardLayout, ButtonComponent},
  data() {
    return {
      name: '',
      role: this.$usergroup,
      url: '',
      id: '',
      errors: {
        name: '',
        url: '',
        id: '',
      },
      isSubmitting: false,
    };
  },
  computed: {
    isFormValid() {
      // Check if the form is valid based on both errors and field values
      return !Object.values(this.errors).some(error => error) && this.name && this.url && this.id;
    }
  },
  watch: {
    // Watch for changes in the form fields and validate them
    name() {
      this.errors.name = this.name.trim() ? '' : this.$translate('errors.required_field');
    },
    url() {
      this.errors.url = this.url.trim() ? '' : this.$translate('errors.required_field');
    },
    id() {
      this.errors.id = this.id.trim() ? '' : this.$translate('errors.required_field');
    },
  },
  methods: {
    async getClients() {
      try {
        let response = await this.$axios.request({
          url: '/site/clients',
          method: 'GET',
        });
        this.clients = response.data.clients;
      } catch (error) {
        console.error('Error fetching clients:', error);
        this.$toast.error(this.$translate('site.add_site.messages.error'));
      }

    },
    validateForm() {
      this.errors = {};  // Reset errors before validation

      // Validate Site ID
      if (!this.id) {
        this.errors.id = 'Site ID is required.';
      }

      // Validate Site Name
      if (!this.name) {
        this.errors.name = 'Site name is required.';
      }

      // Validate Site URL
      if (!this.url) {
        this.errors.url = 'Site URL is required.';
      } else {
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;
        if (!urlPattern.test(this.url)) {
          this.errors.url = 'Please enter a valid URL.';
        }
      }

      return Object.keys(this.errors).length === 0;
    },

    async handleAddSite() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      // First validate the form
      if (!this.validateForm()) {
        this.isSubmitting = false; // Allow submission attempt again if validation fails
        return;
      }

      try {
        let response = await this.$axios.request({
          url: '/site/add',
          method: 'POST',
          data: {
            site_name: this.name,
            site_url: this.url,
            site_id: this.id,
          },
        });

        // Reset fields after successful submission
        this.name = '';
        this.url = '';
        this.id = '';
        this.isSubmitting = false;

        if (response.status === 200) {
          this.$toast.success(this.$translate('site.add_site.messages.success'));
          this.$router.push('/dashboard/sites/manage');
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$toast.error(this.$translate('site.add_site.messages.error'));
      }
    },
  },
  mounted(){
    if(this.role === this.$defaultUsergroups.superadmin) this.getClients();
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}
</style>
