<template>
  <div v-if="show" class="p-4 text-center mx-auto text-gray-500">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
