<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">{{ $translate('products.add_product.title') }}</h2>
        <form @submit.prevent="createProduct">
          <!-- Client ID -->
          <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
            <select
                v-model="clientId"
                @change="handleClientChange(clientId)"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option value="" disabled>{{ $translate('select_client') }}</option>
              <option v-for="client in clients" :key="client.id" :value="client.id">
                {{ client.company_name }}
              </option>
            </select>
          </div>
          <!-- Brand Name -->
          <div class="mb-4">
            <select
                v-model="brandName"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option value="" disabled selected>{{ $translate('select_brand') }}</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
            <p v-if="errors.brandName" class="text-red-500 text-sm">{{ errors.brandName }}</p>
          </div>
          <!-- Product ID -->
          <div class="mb-4">
            <input
                type="text"
                v-model="productId"
                :placeholder="$translate('placeholders.product_id')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.productId" class="text-red-500 text-sm">{{ errors.productId }}</p>
          </div>

          <!-- Product Name -->
          <div class="mb-4">
            <input
                type="text"
                v-model="productName"
                :placeholder="$translate('placeholders.product_name')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.productName" class="text-red-500 text-sm">{{ errors.productName }}</p>
          </div>

          <!-- Contact Number -->
          <div class="mb-4">
            <input
                type="text"
                v-model="contactNumber"
                :placeholder="$translate('placeholders.contact_number')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.contactNumber" class="text-red-500 text-sm">{{ errors.contactNumber }}</p>
          </div>

          <!-- Product Description -->
          <div class="mb-4">
            <input
                type="text"
                v-model="productDescription"
                :placeholder="$translate('placeholders.product_description')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.productDescription" class="text-red-500 text-sm">{{ errors.productDescription }}</p>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-center mt-6">
            <button
                type="submit"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
            >
              {{ $translate('products.add_product.title') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      brands: [],
      productId: '',
      role: this.$usergroup,
      productName: '',
      productDescription: '',
      clientId: '',
      clients: [],
      brandName: '',
      contactNumber: '',
      errors: {},
    };
  },
  methods: {
    validateForm() {
      this.errors = {};

      // Validate product ID
      if (!this.productId) {
        this.errors.productId = 'Product ID is required.';
      }

      // Validate product name
      if (!this.productName) {
        this.errors.productName = 'Product name is required.';
      }

      // Validate contact number (simple check for digits)
      const contactPattern = /^[0-9]+$/;
      if (!this.contactNumber) {
        this.errors.contactNumber = 'Contact number is required.';
      } else if (!contactPattern.test(this.contactNumber)) {
        this.errors.contactNumber = 'Please enter a valid contact number (only digits).';
      }

      // Validate product description
      if (!this.productDescription) {
        this.errors.productDescription = 'Product description is required.';
      }

      // Validate brand selection
      if (!this.brandName) {
        this.errors.brandName = 'Brand selection is required.';
      }
      return Object.keys(this.errors).length === 0;
    },
    async fetchOptions() {
      try {
        const response = await this.$axios.request({
          method: 'GET',
          url: '/brands',
        });
        this.brands = response.data.brands;
        this.clients = response.data.clients;
        console.log('Brands:', this.brands);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async handleClientChange(clientId) {
      try {
        let response = await this.$axios.request({
          method: 'GET',
          url: `/brands/fetch/${clientId}`,
        });

        this.brands = response.data.brands;
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async fetchClients() {
      try {
        let response = await this.$axios.request({
          method: 'GET',
          url: '/clients/fetch',
        });
        this.clients = response.data.clients;
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    },
    async createProduct() {
      if (this.validateForm()) {
        try {
          const response = await this.$axios.request({
            url: '/products',
            method: 'POST',
            data: {
              productId: this.productId,
              productName: this.productName,
              productDescription: this.productDescription,
              contactNumber: this.contactNumber,
              brandId: this.brandName,
              clientId : this.clientId
            }
          });

          if (response.data.status === 'success') {
            this.$toast.success(this.$translate('products.add_product.messages.success'));
            this.$router.push('/dashboard/products/manage');
          }
          // Reset the form
          this.productId = '';
          this.productName = '';
          this.contactNumber = '';
          this.productDescription = '';
          this.clientId = '';
          this.brandName = '';
        } catch (error) {
          console.error('Error creating product:', error);
        }
      }
    },
    async fetchBrands() {
      try {
        const response = await this.$axios.request({
          method: 'GET',
          url: '/brands/id',
        });
        this.brands = response.data.brands;
        this.clients = response.data.clients;
        console.log('Brands:', this.brands);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
  },
  mounted(){

  },
  created() {
    if(this.role !== this.$defaultUsergroups.superadmin) {
      this.fetchBrands();
    }
    if(this.role === this.$defaultUsergroups.superadmin) {
      this.fetchClients();
    }

  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}
</style>
