<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto mt-8">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">{{ $translate('commission.add_new_contracts.title')}}</h2>
        <form>
          <div class="mt-6 bg-gray-200 p-4 rounded-lg shadow-md mb-6" v-show="this.role !== this.$defaultUsergroups.superadmin || this.selectedClientId">
            <h3 class="text-xl text-orange-500 font-semibold mb-4 text-center">{{ $translate('commission.add_new_contracts.client_information')}}</h3>
            <div class="text-left text-lg text-gray-700">
              <p><strong class="text-admin-200">{{ $translate('client_id')}}:</strong> <span class="text-black">{{ clientId }}</span></p>
              <p><strong class="text-admin-200">{{ $translate('client_name')}}:</strong> <span class="text-black">{{ clientName }}</span></p>
              <p><strong class="text-admin-200">{{ $translate('companyName')}}:</strong> <span class="text-black">{{ clientCompanyName }}</span></p>
            </div>
          </div>
          <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
            <select  @change="handleClientChange" v-model="selectedClientId" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !clientId}">
              <option value="" disabled>{{ $translate('select_client')}}</option>
              <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
            </select>
          </div>
          <h3 class="text-xl text-orange-500 font-semibold mb-2">{{ $translate('commission.add_new_contracts.contract_details')}}</h3>
          <div class="mb-4">
            <input
                type="text"
                v-model="contractName"
                :placeholder="$translate('commission.add_new_contracts.contract_name')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                :class="{'border-red-500': !contractName}"
                required
            />
          </div>
          <div class="mb-4">
            <select v-model="selectedBrand" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !selectedBrand}">
              <option value="">{{ $translate('select_brand')}}</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
            </select>
          </div>
          <div class="mb-4">
            <select v-model="selectedProduct" :disabled="loadingProducts" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500">
              <option value="">{{ $translate('select_product')}}</option>
              <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
            </select>
            <div v-if="loadingProducts" class="text-sm text-gray-500">{{ $translate('loading')}}</div>
          </div>
          <h3 class="text-xl text-orange-500 font-semibold mb-2">Select Affiliate</h3>
          <div class="mb-4">
            <select v-model="selectedAffiliate" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !selectedAffiliate}">
              <option value="">Select Affiliate</option>
              <option v-for="affiliate in affiliates" :key="affiliate.id" :value="affiliate">{{ affiliate.name }}</option>
            </select>
            <div v-if="loadingAffiliates" class="text-sm text-gray-500">Loading affiliates...</div>
          </div>
          <div  v-show="selectedAffiliate" class="mt-6 bg-gray-200 p-4 rounded-lg shadow-md mb-4">
            <h3 class="text-xl text-orange-500 font-semibold mb-4 text-center">Affiliate Information</h3>
            <div class="text-left text-lg text-gray-700">
              <p><strong class="text-admin-200">{{ $translate('affiliate_id')}}:</strong> <span class="text-black">{{ selectedAffiliate.id }}</span></p>
              <p><strong class="text-admin-200">{{ $translate('affiliate_name')}}:</strong> <span class="text-black">{{ selectedAffiliate.name }}</span></p>
            </div>
          </div>
          <h3 class="text-xl text-orange-500 font-semibold mb-2">{{ $translate('commission.add_new_contracts.min_payout_threshold')}}</h3>
          <div class="mb-4">
            <input
                type="text"
                v-model="minPayout"
                :placeholder="$translate('placeholders.min_payout_threshold')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
            />
          </div>
          <div class="mb-4">
            <select v-model="paymentTerm" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !revenueModel}">
              <option value="" disabled>{{ $translate('commission.add_new_contracts.payment_term')}}</option>
              <option value="weekly">{{ $translate('commission.add_new_contracts.weekly')}}</option>
              <option value="biweekly">{{ $translate('commission.add_new_contracts.biweekly')}}</option>
              <option value="monthly">{{ $translate('commission.add_new_contracts.monthly')}}</option>
            </select>
          </div>
          <div class="flex justify-center mt-6">
             <span @click="handleAddContract">
                <ButtonComponent
                    class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"

                >
                 {{ $translate('commission.add_new_contracts.add_contract')}}
                </ButtonComponent>
             </span>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      minPayout: '',
      role: this.$usergroup,
      paymentTerm:'',
      contractName: '',
      isClientSelected: false,
      selectedClientId: '',
      clients : [],
      clientId: "",
      clientName: "",
      clientCompanyName: '',
      selectedBrand: "",
      selectedProduct: "",
      selectedAffiliate: "",
      revenueModel: '',
      flatPercentage: null,
      revShareMin: null,
      revShareMax: null,
      revSharePercentage: null,
      cpaModel: '',
      playerLevel: null,
      selectedCommission: '',
      commissions : [],
      brands: [],
      products: [],
      affiliates:[],
      filteredProducts: [],
      filteredAffiliates: [],
      loadingProducts: false,
      loadingAffiliates: false,
    };
  },
  methods: {
    handleClientChange(){
      console.log(this.clients);
      this.clientId = this.selectedClientId;
      this.clientName = this.clients.find(client => client.id === this.selectedClientId).name;
      this.clientCompanyName = this.clients.find(client => client.id === this.selectedClientId).companyName;

    },
    async getContract() {
      try {
        let response = await this.$axios.request({
          method: 'GET',
          url: '/contracts/id',
        });

        this.clientId = response.data.current_user.id;
        this.clientName = response.data.current_user.first_name + ' ' + response.data.current_user.last_name;
        this.clientCompanyName = response.data.current_user.company_name;
        this.brands = response.data.brands.map(brand => ({
          id: brand.id,
          name: brand.name
        }));

        this.products = response.data.products;
      } catch (error) {
        console.error('Error fetching contract:', error);
        this.$toast.error(this.$translate('commission.add_new_contracts.messages.error'));
      }
    },
    async fetchClients() {
      try {
        let response = await this.$axios.request({
          method: 'GET',
          url: '/contracts/clients'
        });

        this.clients = response.data.clients.map(client => ({
          id: client.id,
          name: client.first_name + ' ' + client.last_name + ' • ' + client.email,
          companyName: client.company_name
        }));
      } catch (error) {
        console.error('Error fetching clients:', error);
        this.$toast.error(this.$translate('commission.add_new_contracts.messages.error'));
      }
    },
    async fetchAffiliates() {
      try {
        let response = await this.$axios.request({
          method: 'GET',
          url: '/affiliates/manage'
        });
        this.affiliates = response.data.users.map((aff) => {
          return {
            id: aff.id,
            name: aff.first_name + ' ' + aff.last_name + ' • ' + aff.email
          };
        });
      } catch (error) {
        console.error('Error fetching affiliates:', error);
        this.$toast.error(this.$translate('commission.add_new_contracts.messages.error'));
      }
    },
    resetForm(){
      this.contractName = '';
      this.selectedBrand = '';
      this.selectedProduct = '';
      this.selectedAffiliate = '';
      this.minPayout = '';
      this.paymentTerm = '';
    },
    async handleAddContract() {
      const payload = {
        contractName: this.contractName,
        selectedBrand: this.selectedBrand,
        selectedProduct: this.selectedProduct,
        selectedAffiliate: this.selectedAffiliate.id,
        minPayout: this.minPayout,
        paymentTerm: this.paymentTerm,
        ...(this.role === this.$defaultUsergroups.superadmin && { clientId: this.selectedClientId  })
      }
      try {
        let response = await this.$axios.request({
          method: 'POST',
          url: '/contracts/id',
          data: payload
        });
        if(response.status === 200){
          this.$toast.success(this.$translate('commission.add_new_contracts.messages.success'));
          this.$router.push('/dashboard/commission/contracts/manage');
        } else {
          this.$toast.error(this.$translate('commission.add_new_contracts.messages.error'));
        }
      } catch (error) {
        console.error('Error adding contract:', error);
        this.$toast.error(this.$translate('commission.add_new_contracts.messages.error'));
      }
      this.resetForm();

    },
    isRevenueFieldsValid() {
      if (this.revenueModel === "flat") {
        return this.flatPercentage;
      }
      if (this.revenueModel === "rev_share") {
        return this.revShareMin && this.revShareMax && this.revSharePercentage;
      }
      return false;
    }
  },
  mounted(){
    this.getContract();
    this.fetchAffiliates();
    if(this.role === this.$defaultUsergroups.superadmin){
      this.fetchClients();
    }
  }
};
</script>
