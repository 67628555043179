<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">{{$translate('media.upload_media.title')}}</h2>
        <form @submit.prevent="sendUploadMedia">
          <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
            <select
                v-model="client"
                @change="handleClientChange(client)"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option disabled value="">{{ $translate('select_client')}}</option>
              <option v-for="client in clients" :key="client.id" :value="client.id">
                {{ client.company_name }}
              </option>
            </select>

          </div>
          <div class="mb-2 flex gap-4">
            <input
                type="file"
                multiple
                @change="handleFileChange"
                accept=".jpg,.jpeg,.png,.webp"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <p class="text-sm text-gray-400 mb-4">{{ $translate('media.upload_media.max_file_size') }}</p>
          <div class="mb-4 flex gap-4">
            <select
                v-model="brand"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option disabled value="">{{ $translate('select_brand')}}</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
          <div class="mb-4 flex gap-4">
            <select
                v-model="product"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option disabled value="">{{ $translate('select_product')}}</option>
              <option v-for="product in products" :key="product.id" :value="product.id">
                {{ product.name }}
              </option>
            </select>
          </div>
          <div class="mb-4 flex gap-4">
            <select
                v-model="source"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option disabled value="">{{ $translate('select_source')}}</option>
              <option v-for="source in sources" :key="source.id" :value="source.id">
                {{ source.name }}
              </option>
            </select>
          </div>
          <div class="mb-4">
            <textarea
                v-model="description"
                :placeholder= "$translate('media.upload_media.add_a_description')"
                rows="4"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            ></textarea>
          </div>
          <div class="flex justify-center mt-6">
            <ButtonComponent type="submit"  class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800">
              {{ $translate('media.upload_media.upload') }}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      files: [],
      role: this.$usergroup,
      description: '',
      brand: '',
      brands: [],
      client: '',
      clients: [],
      product: '',
      products: [],
      source: '',
      sources: [],
    };
  },
  methods: {
    async sendUploadMedia() {
      if(this.role === this.$defaultUsergroups.superadmin){
        if (!this.client) {
          this.$toast.error('Please select a client');
          return;
        }
      }
      if (!this.brand) {
        this.$toast.error('Please select a brand');
        return;
      }
      if (!this.product) {
        this.$toast.error('Please select a product');
        return;
      }
      if (!this.source) {
        this.$toast.error('Please select a source');
        return;
      }
      if (!this.description.trim()) {
        this.$toast.error('Please provide a description');
        return;
      }
      if (this.files.length === 0) {
        this.$toast.error('Please select an image to upload');
        return;
      }
      const file = this.files[0];

      try {
        const base64Image = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        const payload = {
          brand_id: this.brand,
          product_id: this.product,
          source_id: this.source,
          description: this.description,
          image: base64Image,
          ...(this.role === this.$defaultUsergroups.superadmin && { client_id: this.client }),
        };

        const response = await this.$axios.request({
          url: '/media_library/upload',
          method: 'POST',
          data: payload,
        });

        if (response.status === 200) {
          this.$toast.success('Media Uploaded Successfully');
          this.$router.push('/dashboard/media/library');
          this.clearForm();
        }
      } catch (error) {
        console.error('Error uploading media:', error);
        this.$toast.error('Error uploading media. Please try again.');
      }
    },
    async fetchClients() {
      try {
        let response = await this.$axios.request({
          url: '/media_library/clients',
          method: 'GET',
        });
        this.clients = response.data.clients;
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    },
    async fetchOptions() {
      try {
        const response = await this.$axios.request({
          url: '/media_library/upload',
          method: 'GET',
        });

        this.sources = response.data.sources;
        this.brands = response.data.brands;
        this.products = response.data.products;
        this.clients = response.data.clients;
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    },
    async handleClientChange(client_id) {
      try {
        let response = await this.$axios.request({
          url: `/media_library/options/${client_id}`,
          method: 'GET',
        });
        console.log(response);
        this.brands = response.data.brands;
        this.products = response.data.products;
        this.sources = response.data.sources;
      } catch (error) {
        console.error('Error fetching client options:', error);
        this.$toast.error('Error fetching client options. Please try again.');
      }
    },
    handleFileChange(event) {
      const acceptedFiles = ['image/jpeg', 'image/png', 'image/webp'];
      const maxSize = 2 * 1024 * 1024;

      this.files = Array.from(event.target.files).filter((file) => {
        if (!acceptedFiles.includes(file.type)) {
          this.$toast.error(`File format not supported: ${file.name}`);
          return false;
        }
        if (file.size > maxSize) {
          this.$toast.error(`File size exceeds 2MB: ${file.name}`);
          return false;
        }
        return true;
      });
    },
    clearForm() {
      this.files = [];
      this.description = '';
      this.brand = '';
      this.product = '';
      this.source = '';
    },
  },
  mounted() {
    if(this.role !== this.$defaultUsergroups.superadmin) this.fetchOptions();
    if(this.role === this.$defaultUsergroups.superadmin){
      this.fetchClients();
    }
  },
};
</script>


<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}
</style>
