<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen mt-6">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold text-orange-500">{{ $translate('brands.manage_brands.title')}}</h2>
            </div>
            <div v-if="loading" class="mt-12 text-center text-orange-500">
              Loading brands...
            </div>
            <div v-else class="mt-12">
              <SearchComponent
                :value="searchQuery"
                @update:value="searchQuery = $event"
                v-model="searchQuery"
                :placeholder="$translate('placeholders.search.by_name')"
              />
              <div class="bg-gray-200 rounded-lg overflow-auto">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('brands.manage_brands.name')}}</div>
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('brands.manage_brands.brand_url')}}</div>
                  <div v-show=" this.role === this.$defaultUsergroups.superadmin " class="py-3 px-4 font-semibold flex-1">{{ $translate('client')}}</div>
                  <div class="py-3 px-4 font-semibold flex-none">{{ $translate('action')}}</div>
                </div>
                <div v-for="brand in paginatedBrands" :key="brand.id" class="flex border-b border-gray-400 hover:bg-gray-300">
                  <div class="py-3 px-4 flex-1">{{ brand.name }}</div>
                  <div class="py-3 px-4 flex-1">{{ brand.url }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 px-4 flex-1">{{ this.clients[brand.user_id]?.company_name }}</div>
                  <div class="py-3 px-4 flex-none">
                    <button @click="editBrand(brand)" class="mx-2 bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600">
                      Edit
                    </button>
                    <button @click="confirmDelete(brand.id)" class="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
                      Delete
                    </button>
                  </div>
                </div>
                <EmptyStateComponent
                    :show="Object.keys(brands).length < 1"
                    :message="$translate('brands.manage_brands.no_brands')"
                />
              </div>
            </div>
            <PaginationComponent
              :currentPage="currentPage"
              :totalPages="totalPages"
              @update:currentPage="currentPage = $event"
              v-show="Object.keys(paginatedBrands).length >= 1"
            />


          </div>
        </div>
      </div>

      <!-- Modals -->
      <transition name="fade">
        <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ isEditMode ? 'Edit Brand' : 'Add New Brand' }}</h3>
            <div class="mb-6">
              <label for="brand-name" class="block text-sm font-medium text-gray-700">{{ $translate('brands.manage_brands.brand_name')}}:</label>
              <input
                  id="brand-name"
                  v-model="newBrand.name"
                  type="text"
                  class="border border-gray-300 rounded-lg p-3 w-full focus:ring-2 focus:ring-orange-500"
                  placeholder="Enter brand name"
              />
              <span v-if="errors.name" class="text-red-500 text-sm">{{ errors.name }}</span>
            </div>
            <div class="mb-6">
              <label for="brand-url" class="block text-sm font-medium text-gray-700">{{ $translate('brands.manage_brands.brand_url')}}:</label>
              <input
                  id="brand-url"
                  v-model="newBrand.url"
                  type="url"
                  class="border border-gray-300 rounded-lg p-3 w-full focus:ring-2 focus:ring-orange-500"
                  placeholder="Enter brand URL"
              />
              <span v-if="errors.url" class="text-red-500 text-sm">{{ errors.url }}</span>
            </div>
            <div class="flex justify-between">
              <button
                  @click="handleSubmit"
                  :disabled="!newBrand.name || !newBrand.url"
                  class="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600"
              >
                {{ isEditMode ? 'Update Brand' : 'Add Brand' }}
              </button>
              <button @click="closeModal" class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600">
                {{ $translate('close')}}
              </button>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="isConfirmationModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ $translate('confirm')}}</h3>
            <p class="text-gray-700 mb-4">{{ $translate('are_you_sure')}}</p>
            <div class="flex justify-between">
              <button @click="deleteBrand" class="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600">
                {{ $translate('confirm')}}
              </button>
              <button @click="closeDeleteModal" class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600">
                {{ $translate('cancel')}}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";
import EmptyStateComponent from "@/components/Fields/EmptyStateComponent.vue";

export default {
  components: {EmptyStateComponent, SearchComponent, PaginationComponent, DashboardLayout },
  data() {
    return {
      loading: true,
      searchQuery: "",
      currentPage: 1,
      recordsPerPage: 5,
      totalPages: 1,
      role: this.$usergroup,
      isModalOpen: false,
      isEditMode: false,
      isConfirmationModalOpen: false,
      errors: {},
      brands: [],
      clients: [],
      newBrand: { id: null, name: "", url: "" },
      brandToDelete: null,
    };
  },
  methods: {
    async fetchBrands() {
      try {
        const response = await this.$axios.request({
          method: 'get',
          url: '/brands/id'
        });
        console.log(response);
        this.brands = response.data.brands;
        this.currentPage = 1;
        if(this.role === this.$defaultUsergroups.superadmin) {
          this.clients = response.data.clients;
        }
        console.log(response);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        this.loading = false;
      }
    },
    editBrand(brand) {
      this.newBrand = { ...brand };
      this.isEditMode = true;
      this.isModalOpen = true;
    },
    confirmDelete(brandId) {
      this.brandToDelete = brandId;
      this.isConfirmationModalOpen = true;
    },
    async deleteBrand() {
      try {
        let response = await this.$axios.request({
          method: 'delete',
          url: `/brands/id/${this.brandToDelete}`
        });
        if(response.data.error) {
          return this.$toast.error(this.$translate('brands.manage_brands.messages.delete_error'));
        }
        if(response.status === 200) this.$toast.success(this.$translate('brands.manage_brands.messages.delete_success'));
        await this.fetchBrands();
      } catch (error) {
        console.error("Error deleting brand:", error);
      } finally {
        this.closeDeleteModal();
      }
    },
    async handleSubmit() {
      try {
        const response = await this.$axios.request({
          url: `/brands/id/${this.newBrand.id}`,
          method: 'PUT',
          data: this.newBrand,
        });
        const brandsArray = Object.values(this.brands);
        const index = brandsArray.findIndex((b) => b.id === this.newBrand.id);
        if (index !== -1) {
          this.brands[this.newBrand.id] = response.data.brand;
        }
        if (response.status === 200) {
          this.$toast.success(this.$translate('brands.manage_brands.messages.success'));
        } else {
          return this.$toast.error(this.$translate('brands.manage_brands.messages.error'));
        }
      } catch (error) {
        console.error("Error updating brand:", error);
        this.$toast.error(this.$translate('brands.manage_brands.messages.error'));
      } finally {
        this.isModalOpen = false;
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.brandToDelete = null;
    },
    resetForm() {
      this.newBrand = { id: null, name: "", url: "" };
      this.errors = {};
    },
  },
  async created() {
    await this.fetchBrands();
  },
  computed:{
    totalPages() {
      if(Object.keys(this.filteredBrands).length === 0) {
        return 1;
      }
      return Math.ceil(Object.keys(this.filteredBrands).length/ this.recordsPerPage);
    },
    paginatedBrands() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      const end = start + this.recordsPerPage;
      return Object.values(this.filteredBrands).slice(start, end);
    },
    filteredBrands() {
      if (!this.searchQuery) return Object.values(this.brands);
      const query = this.searchQuery.toLowerCase();
      return Object.keys(this.brands).reduce((acc, key) => {
        const brand = this.brands[key];
        if (brand.name.toLowerCase().includes(query) || brand.url.toLowerCase().includes(query)) {
          acc[key] = brand;
        }
        return acc;
      }, {});
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
}
</style>
