<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md overflow-y-auto">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold mx-auto mb-4 text-orange-500">{{ $translate('commission.manage_contracts.title')}}</h2>
            </div>
            <SearchComponent
                :value="searchQuery"
                @update:value="searchQuery = $event"
                v-model="searchQuery"
                :placeholder="$translate('placeholders.search.by_name')"
            />
              <div class="flex flex-col bg-gray-200 rounded-lg">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('commission.manage_contracts.contract_name')}}</div>
                  <div v-show="this.role === this.$defaultUsergroups.client || this.role === this.$defaultUsergroups.superadmin " class="py-3 px-4 font-semibold flex-1">{{$translate('affiliate')}}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin || this.role === this.$defaultUsergroups.affiliate " class="py-3 px-4 font-semibold flex-1">{{$translate('client')}}</div>
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('product') }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.affiliate" class="py-3 px-4 font-semibold flex-none">{{$translate('action')}}</div>
                </div>
                <div v-for="contract in paginatedContracts" :key="contract.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                  <div class="py-3 px-4 flex-1">{{ contract.name }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.client || this.role === this.$defaultUsergroups.superadmin " class="py-3 px-4 flex-1">{{ contract.affiliate }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin || this.role === this.$defaultUsergroups.affiliate" class="py-3 px-4 flex-1">{{ contract.client }} </div>
                  <div class="py-3 px-4 flex-1">{{ contract.product }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.affiliate" class="py-3 px-4 flex-none">
                    <div class="flex justify-center max-w-xs">
                      <ButtonComponent  class="bg-orange-500 text-white px-4 py-1 rounded hover:bg-orange-600 mx-2">
                        <span @click="deleteContractModal(contract.id)" class="material-icons">delete</span>
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
                <EmptyStateComponent
                    :message="not_found"
                    :show="contracts.length === 0"
                    />
              </div>
            <PaginationComponent
                :currentPage="currentPage"
                :totalPages="totalPages"
                @update:currentPage="currentPage = $event"
                v-show="Object.keys(contracts).length > 0"
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
          title="Delete Confirmation"
          message="Are you sure you want to delete this product?"
          :isOpen="isConfirmationModalOpen"
          @confirm="confirmDeleteContract"
          @close="isConfirmationModalOpen = false"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import EmptyStateComponent from "@/components/Fields/EmptyStateComponent.vue";

export default {
  components: {
    EmptyStateComponent,
    PaginationComponent, SearchComponent, ConfirmationModalComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      contracts: [],
      currentPage: 1,
      recordsPerPage: 5,
      not_found: "No contracts found",
      searchQuery: '',
      products: [],
      affiliates: [],
      role: this.$usergroup,
      isModalOpen: false,
      isEditMode: false,
      newContract: {
        id: null,
        name: '',
        affiliate: '',
        product: '',
      },
      isConfirmationModalOpen: false,
      deleteModalTitle: '',
      deleteModalMessage: '',
      deleteModalActionLabel: '',
      contractIdToDelete: null,

    };
  },
  methods: {
    async deleteContractModal(contractId) {
      this.isConfirmationModalOpen = true;
      this.contractIdToDelete = contractId;
    },
    async confirmDeleteContract() {
      if (!this.contractIdToDelete) return;
      try {
        let response = await this.$axios.request({
          url: `/contracts/id/${this.contractIdToDelete}`,
          method: 'DELETE',
        });

        if (response.status === 200) {
          this.contracts = this.contracts.filter(contract => contract.id !== this.contractIdToDelete);
          this.$toast.success(this.$translate('commission.manage_contracts.messages.delete_success'));
        } else {
          this.$toast.error(this.$translate('commission.manage_contracts.messages.delete_error'));
        }
      } catch (error) {
        this.$toast.error(this.$translate('commission.manage_contracts.messages.delete_error'));
      } finally {
        this.closeDeleteModal();
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.bannerIdToDelete = null;
    },
    async fetchContracts() {
      try {
        let response = await this.$axios.request({
          url: '/contracts/id',
          method: 'GET'
        });
        if (this.role === this.$defaultUsergroups.superadmin) {
          this.products = response.data.products;
          this.affiliates = response.data.affiliates;
          this.clients = response.data.clients;
          this.contracts = response.data.contracts.map(
              contract => ({
                id: contract.id,
                name: contract.contract_name,
                affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
                product: this.products[contract.product_id]?.name,
                client: this.clients[contract.client_id].company_name || 'N/A'
              })
          );
        }
        if (this.role === this.$defaultUsergroups.affiliate) {
          this.products = response.data.products;
          this.affiliates = response.data.affiliates;
          this.clients = response.data.clients;
          this.contracts = response.data.contracts.map(
              contract => ({
                id: contract.id,
                name: contract.contract_name,
                affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
                product: this.products[contract.product_id].name,
                client: this.clients[contract.client_id] ? this.clients[contract.client_id].company_name : 'N/A'
              })
          );
        }
        if (this.role === this.$defaultUsergroups.client) {
          this.products = response.data.products;
          this.affiliates = response.data.affiliates;
          this.clients = response.data.clients;
          this.contracts = response.data.contracts.map(
              contract => ({
                id: contract.id,
                name: contract.contract_name,
                affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
                product: this.products[contract.product_id].name,
              })
          );
        }
      } catch (error) {
        this.$toast.error(this.$translate('commission.manage_contracts.messages.fetch_error'));
      }
    },
    resetForm() {
      this.newContract = {id: null, name: '', affiliate: '', product: ''};
    },
  },
  mounted() {
    this.fetchContracts();
  },
  computed: {
    filteredContracts() {
      return this.contracts.filter(contract => {
        return contract.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    totalPages(){
      if(this.filteredContracts.length === 0) return 1;
      return Math.ceil(this.filteredContracts.length / this.recordsPerPage);
    },
    paginatedContracts(){
      const start = (this.currentPage - 1) * this.recordsPerPage;
      return this.filteredContracts.slice(start, start + this.recordsPerPage);
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
}
</style>
