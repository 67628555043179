<template>
  <DashboardLayout>
    <div class="max-w-6xl mx-auto rounded-xl bg-admin-100 p-4 shadow-2xl overflow-auto h-full max-h-lg mt-6">
      <h2 class="text-2xl text-admin-200 text-center font-bold mb-8">User Permissions</h2>
      <form @submit.prevent="savePermissions" class="space-y-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          <div
              v-for="permission in paginatedPermissions"
              :key="permission.id"
              class="permission-box p-6 border border-[#f16f24] rounded-lg shadow-md flex flex-col justify-between items-start transition-transform duration-200 ease-in-out hover:scale-105"
          >
            <div class="mb-4">
              <h3 class="text-lg font-semibold">{{ permission.name }}</h3>
              <p class="text-sm text-gray-600">{{ permission.description }}</p>
            </div>
            <div class="flex flex-col items-center justify-center w-full">
              <div class="flex items-center mb-2">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                      type="checkbox"
                      :checked="permission.active"
                      @change="togglePermission(permission)"
                      class="sr-only"
                  />
                  <div class="w-10 h-6 bg-gray-200 rounded-full transition-colors duration-300"></div>
                  <div
                      class="absolute w-4 h-4 bg-white rounded-full shadow transition-transform duration-200 ease-in-out"
                      :class="permission.active ? 'translate-x-4 bg-orange-500' : 'translate-x-0'"
                  ></div>
                </label>
                <span class="text-gray-600 ml-2">{{ permission.active ? 'Active' : 'Inactive' }}</span>
              </div>
              <span
                  @click="viewDetails(permission)"
                  class="cursor-pointer text-gray-700 hover:text-orange-500 material-icons"
              >visibility</span>
            </div>
          </div>
        </div>

        <!-- Pagination Controls -->
        <div class="flex justify-between mt-4">
          <button
              v-if="currentPage.value > 1"
              @click="changePage(currentPage.value - 1)"
              class="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition-colors duration-300"
          >
            Previous
          </button>
          <span class="text-gray-600 ml-2">Page {{ currentPage.value }} of {{ totalPages }}</span>
          <button
              v-if="currentPage.value < totalPages"
              @click="changePage(currentPage.value + 1)"
              class="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition-colors duration-300"
          >
            Next
          </button>
        </div>

      </form>
    </div>
  </DashboardLayout>
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue';
import ButtonComponent from '@/components/Fields/ButtonComponent.vue';
import DashboardLayout from "@/views/DashboardLayout.vue";
// import axios module
import axios from '#src/module/axios.js';

const permissions = reactive([]);
const perPage = 10;

const currentPage = reactive({ value: 1 });
const totalPages = computed(() => Math.ceil(permissions.length / perPage));

const paginatedPermissions = computed(() => {
  const start = (currentPage.value - 1) * perPage;
  const end = currentPage.value * perPage;
  return permissions.slice(start, end);
});

const getAllPermissions = async () => {
  try {
    const response = await axios.request({
      url: '/permissions',
      method: 'GET'
    });
    console.log('Fetched permissions:', response.data);
    permissions.push(...response.data.map(permission => ({
      id: permission.id,
      name: permission.name,
      description: permission.description,
      active: permission.status_id === 1
    })));
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};


onMounted(() => {
  getAllPermissions();
});

// Save Permissions Method
const savePermissions = async () => {
  const updatedPermissions = permissions.map(permission => ({
    id: permission.id,
    status_id: permission.active ? 1 : 0
  }));

  try {
    const response = await axios.request({
      url: '/updatePermissions',
      method: 'PUT',
      data: updatedPermissions
    });
    console.log('Permissions updated:', response.data);
  } catch (error) {
    console.error('Error saving permissions:', error);
  }
};

function changePage(page) {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;
  }
}
function viewDetails(permission) {
  alert(`Viewing details for ${permission.name}`);
}

const togglePermission = async (permission) => {
  permission.active = !permission.active;
  try {
    const response = await axios.request({
      url: `/updatePermissionStatus/${permission.id}`,
      method: 'POST',
      data: {
        status_id: permission.active ? 1 : 0
      }
    });
    console.log('Permission updated:', response.data);
  } catch (error) {
    console.error('Error updating permission status:', error);
  }
};
</script>

<style scoped>
.permission-box {
  transition: transform 0.2s ease;
}

.permission-box:hover {
  transform: scale(1.02);
}

.material-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

/* Toggle switch styles */
label {
  display: inline-flex;
  align-items: center;
}

input[type="checkbox"]:checked + div {
  background-color: #f16f24; /* Orange background when checked */
}

input[type="checkbox"]:checked + div::after {
  transform: translateX(1.25rem);
  background-color: #ffffff; /* White background for switch knob when checked */
}
</style>
