<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md mt-28">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold text-orange-500">{{ $translate('sites.manage_sites.title')}}</h2>
            </div>
            <div class="mt-12">
              <SearchComponent
                  :value="searchQuery"
                  @update:value="searchQuery = $event"
                  v-model="searchQuery"
                  :placeholder="$translate('placeholders.search.by_name')"
              />
              <div class="flex flex-col bg-gray-200 rounded-lg overflow-hidden">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('sites.manage_sites.name')}}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 px-4 font-semibold flex-1">{{ $translate('sites.manage_sites.affiliate')}}</div>
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('sites.manage_sites.url')}}</div>
                  <div class="py-3 px-4 font-semibold flex-none">{{ $translate('action')}}</div>
                </div>
                <div v-for="site in paginatedSites" :key="site.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                  <div class="py-3 px-4 flex-1">{{ site.site_name }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 px-4 flex-1">{{ affiliates[site.user_id] }}</div>
                  <div class="py-3 px-4 flex-1">{{ site.site_url }}</div>
                  <div class="py-3 px-4 flex space-x-2">
                    <button
                        @click="editSite(site)"
                        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                    >
                      <span class="material-icons">edit</span>
                    </button>
                    <button
                      @click="deleteSiteConfirm(site.id)"
                      class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none mt-4"
                    >
                      <span class="material-icons">delete</span>
                    </button>
                  </div>
                </div>
                <EmptyStateComponent
                    :message="not_found"
                    :show="paginatedSites.length === 0"
                />
              </div>
              <PaginationComponent
                  :currentPage="currentPage"
                  :totalPages="totalPages"
                  @update:currentPage="currentPage = $event"
                  v-show="Object.keys(sites).length > 0"
                  />
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for editing sites -->
      <transition name="fade">
        <div v-if="isModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div class="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">{{ $translate('sites.manage_sites.edit_site')}}</h2>
            <form  @submit.prevent="">
              <div class="mb-4">
                <input
                    type="text"
                    v-model="newSite.site_name"
                    :placeholder="$translate('placeholders.site_name')"
                    class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-4">
                <input
                    type="text"
                    v-model="newSite.site_url"
                    :placeholder="$translate('placeholders.site_url')"
                    class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="flex justify-center gap-4 mt-6">
                <button
                    type="submit"
                    @click="updateSite"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                >
                  {{$translate('update')}}
                </button>
                <button
                    @click="closeModal"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none mt-4"
                >
                  {{$translate('cancel')}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </transition>

      <ConfirmationModalComponent
          title="Delete Confirmation"
          message="Are you sure you want to delete this site? This action cannot be undone."
          :isOpen="deleteModal"
          @confirm="deleteSite(this.siteIdToDelete)"
          @close="deleteModal = false"
      />




    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";
import EmptyStateComponent from "@/components/Fields/EmptyStateComponent.vue";

export default {
  components: {EmptyStateComponent, SearchComponent, PaginationComponent, ConfirmationModalComponent, DashboardLayout},
  data() {
    return {
      sites: [],
      currentPage: 1,
      not_found: "No sites found",
      searchQuery: '',
      recordsPerPage: 5,
      siteIdToDelete: null,
      deleteModal: false,
      role: this.$usergroup,
      affiliates: [],
      isModalOpen: false,
      newSite: {
        id: null,
        name: '',
        url: '',
      },
    };
  },
  methods: {
    editSite(site) {
      this.isModalOpen = true;
      this.newSite = {...site};
    },
    deleteSiteConfirm(site_id) {
      this.siteIdToDelete = site_id;
      this.deleteModal = true;
    },
    async deleteSite(site_id) {
      if (!this.siteIdToDelete) return;

      try {
        let response = await this.$axios.request({
          url: `/site/delete/${site_id}`,
          method: 'DELETE'
        });
        this.deleteModal = false;
        if (response.status === 200) {
          this.$toast.success(this.$translate('site.manage_sites.messages.success'));
          this.sites = this.sites.filter(site => site.id !== site_id);
        } else {
          this.$toast.error(this.$translate('site.manage_sites.messages.error'));
        }
      } catch (error) {
        console.error('Error deleting site:', error);
        this.$toast.error(this.$translate('site.manage_sites.messages.error'));
      }
    },
    async updateSite() {
      try {
        const response = await this.$axios.request({
          url: `/site/update/${this.newSite.id}`,
          method: 'PUT',
          data: this.newSite
        });
        const updatedSite = response.data.site;
        const message = response.data.message;

        const index = this.sites.findIndex(site => site.id === updatedSite.id);
        if (index !== -1) {
          this.sites.splice(index, 1, updatedSite);
        }
        this.$toast.success(this.$translate('site.manage_sites.messages.success'));
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.error(this.$translate('site.manage_sites.messages.error'));
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    resetForm() {
      this.newSite = {
        id: null,
        name: '',
        url: '',
      };
    },
    async fetchSites() {
      try {
        const response = await this.$axios.request({
          url: '/site/index',
          method: 'GET',
        });
        this.sites = response.data.sites;
        this.currentPage = 1;



        this.affiliates = response.data.users;
      } catch (error) {
        console.error('Error fetching sites:', error);
      }
    },
  },
  mounted() {
    this.fetchSites();
  },
  computed: {
    totalPages() {
      if(this.filteredSites.length === 0) return 1;
      return Math.ceil(this.filteredSites.length / this.recordsPerPage);
    },
    paginatedSites() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      const end = start + this.recordsPerPage;
      return this.filteredSites.slice(start, end);
    },
    filteredSites(){
      return this.sites.filter(site => {
        return site.site_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            site.site_url.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    }
  }
};
</script>
