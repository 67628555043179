<template>
  <DashboardLayout>
    <div class="flex justify-center items-center bg-gradient-white">
      <div class="container max-w-7xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Campaigns</h2>
        <SearchComponent
            :value="searchQuery"
            @update:value="searchQuery = $event"
            v-model="searchQuery"
            :placeholder="$translate('placeholders.search.by_name')"
        />
        <div class="flex bg-orange-500 text-white py-3 rounded-t-lg font-bold">
          <div class="flex-[2] text-center">Campaign Name</div>
          <div class="flex-[2] text-center">Tracking ID</div>
          <div class="flex-[1.5] text-center">Affiliate</div>
          <div v-show="this.role === this.$defaultUsergroups.superadmin" class="flex-[1.5] text-center">Client</div>
          <div class="flex-[1.5] text-center">Actions</div>
        </div>
        <div
            v-for="track in paginatedCampaigns"
            :key="track.id"
            class="flex items-center border-b border-gray-300 hover:bg-gray-300 transition-colors duration-200 py-3"
        >
          <div class="flex-[2] text-center">{{ track.campaign_name }}</div>
          <div class="flex-[2] text-center">{{ track.tracking_id }}</div>
          <div class="flex-[1.5] text-center">{{ track.affiliate_name }}</div>
          <div v-show="this.role === this.$defaultUsergroups.superadmin" class="flex-[1.5] text-center">{{ track.client_name }}</div>
          <div class="flex-[1.5] flex justify-center gap-2">
            <button
                class="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
                @click="viewCampaign(track.tracking_id)"
            >
              <span class="material-icons">visibility</span>
            </button>
          </div>
        </div>
        <EmptyStateComponent
            :message="not_found"
            :show="filteredCampaigns.length === 0"
            />
        <PaginationComponent
            :currentPage="currentPageOut"
            :totalPages="totalPagesOut"
            @update:currentPage="currentPageOut = $event"
            v-show="filteredCampaigns.length > 0"
            />
      </div>
    </div>
    <CampaignDetailsModal
        :isOpen="isOpen"
        :metadata="metadata"
        :selectedCampaign="selectedCampaign"
        :paginatedData="paginatedData"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @close="isOpen = false"
        @update:currentPage="currentPage = $event"
    />
  </DashboardLayout>
</template>

<script>
import { defineComponent } from "vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";
import CampaignDetailsModal from "@/components/Fields/CampaignDetailsModal.vue";
import EmptyStateComponent from "@/components/Fields/EmptyStateComponent.vue";

export default defineComponent({
  components: {EmptyStateComponent, CampaignDetailsModal, SearchComponent, DashboardLayout, PaginationComponent },
  data() {
    return {
      currentPage: 1,
      searchQuery: "",
      currentPageOut: 1,
      recordsPerPage: 5,
      not_found: "No campaigns found",
      recordsPerPageOut:10,
      trackingIds: [],
      role: this.$usergroup,
      selectedCampaign: [],
      metadata: {
        tracking_id: " ",
        redirect_url: " ",
      },
      isOpen: false,
    };
  },
  methods: {
    async getCampaigns() {
      try {
        const response = await this.$axios.request({
          url: "/campaign",
          method: "get",
        });
        this.trackingIds = response.data.tracking_ids || [];
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    },
    async viewCampaign(trackingId) {
      try {
        const response = await this.$axios.request({
          url: `/campaign/view/${trackingId}`,
          method: "get",
        });
        if (response.status === 200) {
          this.selectedCampaign = response.data.tracking_data || {};


          console.log(typeof this.selectedCampaign);
          this.metadata = response.data.metadata || {};
          this.isOpen = true;
          this.currentPage = 1;
        }
      } catch (error) {
        console.error("Error fetching campaign details:", error);
      }
    },
  },
  computed: {
    totalPages() {
      if(this.selectedCampaign.length === 0) return 1;
      return this.selectedCampaign.length > 0
          ? Math.ceil(this.selectedCampaign.length / this.recordsPerPage)
          : 1;
    },
    totalPagesOut(){
      if(this.filteredCampaigns.length === 0) return 1;
      return this.filteredCampaigns.length > 0
          ? Math.ceil(this.filteredCampaigns.length / this.recordsPerPageOut)
          : 1;
    },
    paginatedCampaigns(){
      const start = (this.currentPageOut - 1) * this.recordsPerPageOut;
      return this.filteredCampaigns.slice(start, start + this.recordsPerPageOut);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      const end = start + this.recordsPerPage;
      return Object.values(this.selectedCampaign).slice(start, end);
    },
    filteredCampaigns() {
      return this.trackingIds.filter((campaign) => {
        return campaign.campaign_name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {
    this.getCampaigns();
  },
});
</script>

<style scoped>
</style>
