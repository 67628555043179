<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">
          {{ $translate('sources.add_sources.title') }}
        </h2>
        <form @submit.prevent="handleAddSource">
          <div class="mb-4">
            <input
                type="text"
                v-model.trim="name"
                @input="validateName"
                :placeholder="$translate('placeholders.source_name')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.name" class="text-red-500 text-sm mt-1">{{ errors.name }}</p>
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model.trim="url"
                @input="validateUrl"
                :placeholder="$translate('placeholders.source_url')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <p v-if="errors.url" class="text-red-500 text-sm mt-1">{{ errors.url }}</p>
          </div>
          <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
            <select class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500">
              <option value="">{{ $translate('select_client') }}</option>
              <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.company_name }}</option>
            </select>
          </div>
          <div class="flex justify-center mt-6">
            <button
                :disabled="isSubmitting || hasErrors"
                type="submit"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
            >
              {{ $translate('add_source') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      name: '',
      url: '',
      clients: [],
      role: this.$usergroup,
      isSubmitting: false,
      errors: {
        name: '',
        url: '',
      },
    };
  },
  computed: {
    hasErrors() {
      return !!(this.errors.name || this.errors.url);
    },
  },
  methods: {
    async getClients() {
      try {
        let response = await this.$axios.request({
          url: '/source/clients',
          method: 'GET',
        });

        this.clients = response.data.clients;
      } catch (error) {
        console.error("Error fetching clients:", error);
        this.$toast.error(this.$translate('sources.add_sources.messages.error_fetching_clients'));
      }
    },
    validateName() {
      this.errors.name = this.name.trim()
          ? ''
          : this.$translate('errors.required_field');
    },
    validateUrl() {
      if (!this.url.trim()) {
        this.errors.url = this.$translate('errors.required_field');
      } else if (!this.isValidUrl(this.url)) {
        this.errors.url = this.$translate('errors.invalid_url');
      } else {
        this.errors.url = '';
      }
    },
    isValidUrl(url) {
      const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
      return pattern.test(url.trim());
    },
    async handleAddSource() {
      this.validateName();
      this.validateUrl();

      if (this.hasErrors) return;

      this.isSubmitting = true;
      try {
        let response = await this.$axios.request({
          url: '/source/id',
          method: 'POST',
          data: {
            name: this.name,
            url: this.url,
          },
        });

        console.log("Source added:", response.data);
        this.name = '';
        this.url = '';
        this.errors = { name: '', url: '' };
        this.isSubmitting = false;
        this.$toast.success(this.$translate('sources.add_sources.messages.success'));
        this.$router.push('/dashboard/sources/manage');
      } catch (error) {
        this.isSubmitting = false;
        this.$toast.error(this.$translate('sources.add_sources.messages.error'));
      }
    },
  },
  mounted(){
    if(this.role === this.$defaultUsergroups.superadmin){
      this.getClients();
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}
.text-red-500 {
  color: #ff4d4d;
}
</style>