import { createApp } from 'vue';
import App from './App.vue';
import { useToast } from "vue-toastification";
import './main.css';
import 'material-design-icons/iconfont/material-icons.css';
import router from "@/module/router";
import axios from './module/axios';
import Dialog from 'vue3-dialog';
import "vue-toastification/dist/index.css";
import { createI18n } from 'vue-i18n';
import en from './locale/en.json';
import el from './locale/el.json';


async function getConfig(){

        let response = await axios.request(
                {
                        method: 'get',
                        url: '/config',
                }
        );

        return response.data;
}

let config = await getConfig();



let selectedLocale = config.locale;
let usergroup = config.group;


const i18n = createI18n({
        locale: selectedLocale,
        fallbackLocale: 'en',
        availableLocales: ['en', 'el'],
        messages: { en, el }
});

const app = createApp(App);

app.use(Dialog);
app.use(i18n);
app.config.globalProperties.$config = await config;
app.config.globalProperties.$user = await config.user || null;
app.config.globalProperties.$defaultUsergroups = await config.default_usergroups || null;
app.config.globalProperties.$usergroup = await usergroup || null ;
app.config.globalProperties.$reloadConfig = async () => {
        app.config.globalProperties.$config = await getConfig();
        app.config.globalProperties.$user = await app.config.globalProperties.$config.user || null;
        app.config.globalProperties.$defaultUsergroups = await app.config.globalProperties.$config.default_usergroups || null;
        app.config.globalProperties.$usergroup = await app.config.globalProperties.$config.group || null;
};
app.config.globalProperties.$translate = (key) => i18n.global.t(key);
app.config.globalProperties.i18n = i18n;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$toast = useToast({
        position: "bottom-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
});
app.use(router);

app.mount('#app');