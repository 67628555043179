<template>
  <DashboardLayout>
    <div class="flex justify-center items-center mt-6">
      <div class="container max-w-6xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Pending Affiliates</h2>
        <div class="flex flex-col">
          <SearchComponent
              :value="searchQuery"
              @update:value="searchQuery = $event"
              v-model="searchQuery"
              :placeholder="$translate('placeholders.search.by_name')"
          />
          <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
            <div class="flex-1 font-semibold">Affiliate Name</div>
            <div class="flex-1 font-semibold">Affiliate Email</div>
            <div class="flex-1 font-semibold">Interested in</div>
            <div class="flex-1 font-semibold">Status</div>
            <div class="flex-1 font-semibold">Actions</div>
          </div>
          <div class="flex flex-col">
            <div
                v-for="affiliate in paginatedAffiliates"
                :key="affiliate.id"
                class="flex justify-between items-center p-3 border-b border-gray-300 hover:bg-gray-200"
            >
              <div class="flex-1">{{ affiliate.name }}</div>
              <div class="flex-1">{{ affiliate.email }}</div>
              <div class="flex-1">{{ affiliate.brands }}</div>
              <div class="flex-1 text-yellow-500">Pending Approval</div>
              <div class="flex-1 flex justify-center">
                <ButtonComponent @click="approveAffiliate(affiliate.id)" class="bg-admin-200 text-white px-2 py-1 rounded !hover:bg-green-500 mx-2">
                  Approve
                </ButtonComponent>
                <ButtonComponent @click="rejectAffiliate(affiliate.id)" class="bg-admin-200 text-white px-2 py-1 rounded hover:bg-red-600 mx-2">
                  Reject
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>

        <PaginationComponent
            :currentPage="currentPage"
            :totalPages="totalPages"
            @update:currentPage="currentPage = $event"
        />


      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";

export default {
  components: {SearchComponent, PaginationComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      recordsPerPage:5,
      searchQuery: '',
      currentPage: 1,
      pendingAffiliates: [],
      newPendingAffiliate: {
        name: '',
        email: ''
      }
    };
  },
  methods: {
    approveAffiliate(id) {
      const affiliate = this.pendingAffiliates.find(a => a.id === id);
      if (affiliate) {
        this.sendApproveRequest(affiliate.id);

        this.pendingAffiliates = this.pendingAffiliates.filter(a => a.id !== id);
      }
    },
    async sendApproveRequest(userId) {
      try {
        const response = await this.$axios.post('/affiliates/approve', {
          user_id: userId
        });

        if (response.status === 200) {
          this.$toast.success('Affiliate approved successfully!');
        } else {
          console.error("Failed to approve affiliate. Response:", response);
        }
      } catch (error) {
        console.error("Error approving affiliate:", error);
      }
    },
    async fetchPendingAffiliates() {
      try {
        const response = await this.$axios.request({
          method: 'get',
          url: '/affiliates/pending'
        });
        console.log(response);
        if (response.status === 200 && response.data) {
          this.pendingAffiliates = response.data.affiliates.map(affiliate => ({
            id: affiliate.id,
            name: `${affiliate.first_name} ${affiliate.last_name}`,
            email: affiliate.email,
            brands: affiliate.brands
          }));
        } else {
          console.error("Failed to fetch pending affiliates. Response:", response);
        }
      } catch (error) {
        console.error("Error fetching pending affiliates:", error);
      }
    },
    rejectAffiliate(id) {
      const affiliate = this.pendingAffiliates.find(a => a.id === id);
      if (affiliate) {
        this.pendingAffiliates = this.pendingAffiliates.filter(a => a.id !== id);
        this.$toast.warning(`Affiliate ${affiliate.name} rejected!`);
      }
    },
  },
  mounted(){
    this.fetchPendingAffiliates()
  },
  computed:{
    totalPages() {
      if(this.pendingAffiliates.length === 0) return 1;
      return Math.ceil(this.filteredAffiliates.length / this.recordsPerPage);
    },
    paginatedAffiliates() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      return this.filteredAffiliates.slice(start, start + this.recordsPerPage);
    },
    filteredAffiliates(){
      return this.pendingAffiliates.filter(affiliate => {
        return affiliate.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            affiliate.email.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}
</style>
